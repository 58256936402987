<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { getLayout } from "@/utils/auth";
  import livLiveConsole from "@/api/live/livLiveConsole";

  export default {
    name: "App",
    data() {
      return {
        audio: null
      }
    },

    created() {
      this.initStyle();
    },
    mounted() {
      // 初始化声音
      this.audio = new Audio(require('@/assets/leave_sound.wav'));
      this.audio.preload = 'auto';
      window.addEventListener('beforeunload', this.handleLeavePage);
      window.addEventListener('unload', this.handleUnloadPage)
      // 腾讯IM 推送消息
      this.$bus.$on('getLivePush', message => {
        // 直播控制台
        if (this.$route.path !== '/live/control') {
          this.$liveNotify({
            duration: 10000,
            message
          })
          this.playSound();
        }
      })
   },
    destroyed() {
      window.removeEventListener('beforeunload', e => this.handleLeavePage(e))
      window.removeEventListener('unload', e => this.handleUnloadPage(e))
    },
    watch:{
      $route(to,from){
        // 判断企业微信权限，目前只有主播，门店，账户管理需要该接口
        const needCheckEnterpriseWechatRouterName = ['user','storeManage']
        const needCheckEnterpriseWechatRouterPath = ['/anchor']
        if(this.$store.state.user.token && (needCheckEnterpriseWechatRouterName.includes(to.name) || needCheckEnterpriseWechatRouterPath.includes(to.path))){
          this.$store.dispatch('CheckUserEnterpriseWechat')
        }
        // 门店设置接口
        const needGetStoreSettingPath = ['/order','/system/systemSetting','/store/index']
        if(this.$store.state.user.token && needGetStoreSettingPath.includes(to.path)){
          this.$store.dispatch('GetStoreSetting')
        }
      }
    },
    methods: {
      playSound() {
        if (this.audio) {
          this.audio.play();
        }
      },
      async initStyle() {
        switch (getLayout()) {
          case "1":
            // require("./styles/smart/index.scss");
            break;
          case "2":
            // require("./styles/tabs/index.scss");
            break;
          case "3":
            require("./styles/live/index.scss");
            break;
        }
      },
      handleLeavePage(e) {
        if (this.$route.path !== '/login') {
          e.preventDefault()
          e.returnValue = ''
          return 'stop publish';
        }
      },
      handleUnloadPage() {
        if (this.$route.path === '/live/control') {
          livLiveConsole.noticUserCloseChrome({
            id: this.$route.query.id
          })
        }
      }
    },
  };
</script>

<style>
  html,
  body {
    overflow-y: hidden;
  }
</style>

<style lang="scss">
  #app {
    min-width: 1200px;
  }

  /* Global */
  .flex {
    display: flex;

    &.inline {
      display: inline-flex;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.between {
      justify-content: space-between;
    }

    &.middle {
      align-items: center;
    }

    &.stretch {
      align-items: stretch;
    }

    &.column {
      flex-direction: column;
    }

    &.average > * {
      flex: 1;
    }
  }

  .flex-1 {
    flex: 1;
  }

  .hidden {
    overflow: hidden;
  }

  .text-center {
    text-align: center;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .clearfix:after {
    content: " ";
    visibility: hidden;
    display: block;
    font-size: 0;
    clear: both;
    height: 0;
  }

  .el-button--text {
    &.no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.black {
      &.black {
        color: #222;

        &:hover,
        &:focus,
        &:visited {
          color: #666;
        }

        &:active {
          color: #000;
        }
      }
    }
  }

  .el-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .el-tooltip__popper.is-light.popper {
    border: 0;
    box-shadow: 0px 4px 30px 0px rgba(71, 92, 110, 0.1);
    padding: 6px 7px;

    .popper__arrow {
      border: 0;
    }
  }

  .el-button.el-button--primary,
  .el-button.el-button--info,
  .el-button.el-button--default,
  .el-button.el-button--danger{
    border-radius: 12px;
  }

  .el-checkbox {
    .el-checkbox__inner {
      border-radius: 4px;
    }
    .el-checkbox__label {
      color: #323232 !important;
    }
  }

  .el-button svg.svg-icon {
    width: 1.1em;
  }

  .el-form-item.is-error .el-input__inner,
  .el-form-item.is-error .el-input__inner:focus,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-textarea__inner:focus,
  .el-form-item.is-error .upload {
    border-color: #ff4949 !important;
  }

  .el-table {
    .cell:empty::before,
    .cell > span:empty::before {
      content: "-";
    }
    .is-leaf {
      .cell:empty:before,
      .cell > span:empty::before {
        content: "-";
      }
    }
  }

  .el-scrollbar .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-tabs__item:focus.is-active.is-focus:not(:active) {
    box-shadow: none !important;
  }
  // 覆盖掉原有样式----------------START------------------
  .search_user{
    .el-input__inner{
      border-right: 0!important;
    }
    .el-input__inner:focus{
      border-right: 1px solid #2969e3!important;
    }
    .el-input-group__append{
      border-left: 0!important;
      background-color: #fff!important;
    }

  }
  // 覆盖掉原有样式----------------END------------------
</style>

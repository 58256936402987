<template>
  <div class="head-bar">
    <el-breadcrumb class="bread" separator="/">
      <el-breadcrumb-item v-for="item in breads" :key="item.title">
        <span
          v-if="item.name !== $route.name"
          @click="$router.push({ path: item.redirect })"
          style="cursor: pointer"
          >{{ item.title }}</span
        >
        <span v-else>{{ item.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex middle">
      <el-popover v-model="msgVisible" popper-class="msg-pop" placement="bottom-end" trigger="click" :visible-arrow="false" @show="getMsgList">
        <div class="info-wrap" slot="reference">
          <div v-if="unreadNum > 0" class="info-content" :class="unreadNum > 99 ? 'less-hundred' : (unreadNum > 10 && unreadNum < 99 ? 'than-ten' : '')">
            {{ unreadNum > 99 ? `${unreadNum}+` : unreadNum }}
          </div>
        </div>
        <div class="msg-pop__hd flex middle between">
          <div class="msg-tab flex middle">
            <div class="msg-tab__item" :class="{ 'msg-tab__item--sel': msgParams.readStatus === '' }" @click="changeMsgList('')">全部</div>
            <div class="msg-tab__item" :class="{ 'msg-tab__item--sel': msgParams.readStatus === 0 }" @click="changeMsgList(0)">未读（{{ unreadNum }}）</div>
            <div v-if="unreadNum > 0" class="msg-tab__item msg-tab__item--read" @click="handleReadAllMsg">全部标为已读</div>
          </div>
          <i class="el-icon-close" @click="msgVisible = false"></i>
        </div>
        <div v-loading="msgLoading" class="msg-pop__bd flex middle">
          <div v-for="(item, index) in msgData.list" :key="item.id" class="live-msg" :class="{ read: item.readStatus }" @click="handleReadMsg(item, index)">
            <div class="live-msg__header flex between">
              <div class="live-info flex middle">
                <template v-if="!['LIVE_START_SERIES', 'LIVE_START_PRODUCT'].includes(item.pushType)">
                  <img :src="item.fromAvatar" alt="" />
                  <div class="live-info__name ellipsis">{{ item.fromUserName }}</div>
                  <div class="live-info__line"></div>
                </template>
                <div class="live-info__desc">{{ typeText[item.pushType] }}</div>
              </div>
              <span>{{ item.dateTime }}</span>
            </div>
            <div class="live-msg__content">
              <div class="live-date flex middle center">
                <div class="live-date__item">
                  {{ item.startTimeArr[0] }}
                </div>
                <div class="live-date__symbol">
                  <div class="live-date__point"></div>
                  <div class="live-date__point"></div>
                </div>
                <div class="live-date__item">
                  {{ item.startTimeArr[1] }}
                </div>
                <div class="live-date__symbol">
                  <div class="live-date__line"></div>
                </div>
                <div class="live-date__item">
                  {{ item.endTimeArr[0] }}
                </div>
                <div class="live-date__symbol">
                  <div class="live-date__point"></div>
                  <div class="live-date__point"></div>
                </div>
                <div class="live-date__item">
                  {{ item.endTimeArr[1] }}
                </div>
              </div>
              <div class="live-msg__date flex middle center">
                {{ item.dateText }}
                <template v-if="item.isToady">
                  （今天）
                </template>
              </div>
            </div>
          </div>
          <div v-if="msgData.list.length === 0 && msgParams.pageNum === 1" class="no-data">当前暂无消息</div>
        </div>
        <div class="msg-pop__ft flex middle center">
          <el-pagination
            :total="msgData.total"
            :current-page="msgParams.pageNum"
            :page-size="msgParams.pageSize"
            layout="prev, pager, next"
            @current-change="onCurrentChange"
          />
        </div>
      </el-popover>
      <el-popover placement="bottom-end" trigger="hover" :visible-arrow="false">
        <span class="el-dropdown-link" slot="reference">
          <el-avatar
            :size="24"
            :src="user.avatar"
            icon="el-icon-user-solid"
          ></el-avatar>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>

        <div class="user-block">
          <div class="user-info">
            <mf-avatar-block
              :avatar="user.avatar"
              :name="user.nickName"
              :desc="`账号：${user.userName}`"
              desc-size="12px"
            />
          </div>

          <div class="operation flex average">
            <el-button type="text" @click="userVisible = true"
              >账号设置</el-button
            >

            <el-button type="text" class="black" @click="logout"
              >安全退出</el-button
            >
          </div>
        </div>
      </el-popover>
    </div>

    <el-dialog
      title="账号设置"
      :close-on-click-modal="false"
      :visible.sync="userVisible"
      @open="initForm"
      :append-to-body="true"
      width="550px"
    >
      <div class="flex between">
        <el-form :model="form" :rules="rules" ref="userForm">
          <el-form-item label="昵称">
            <el-input
              v-model="form.nickName"
              placeholder="请输入昵称"
              :maxlength="50"
              show-word-limit
              clearable
              style="width: 300px"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="当前密码"
            prop="oldPassword"
          >
            <el-input
              v-model="form.oldPassword"
              placeholder="请输入当前密码"
              show-password
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="新密码"
            prop="password"
          >
            <el-input
              v-model="form.password"
              placeholder="请输入新密码"
              show-password
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>

        <div style="flex: 1; text-align: center; padding-top: 30px">
          <mf-uploader
            accept="image/png, image/jpeg"
            @success="uploadAvatar"
          >
            <el-image
              :size="100"
              class="avatar-cover"
              :class="{ 'is-upload': this.form.avatar }"
              fit="cover"
              :src="form.avatar"
            />
          </mf-uploader>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="text" class="black" @click="userVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="updateUserInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MfAvatarBlock from "@/modules/functional/AvatarBlock";
import MfUploader from "@/modules/functional/Uploader";
import { editUserProfile, getLiveNotice, getNoRead, hasRead, hasReadAll } from '@/api/user'

export default {
  name: "Headbar",

  components: {
    MfAvatarBlock,
    MfUploader,
  },

  data() {
    return {
      userVisible: false,
      form: {
        userId: 0,
        nickName: '',
        password: '',
        oldPassword: '',
        avatar: ''
      },
      rules: {
        oldPassword: [{
          validator: (rule, value, callback) => {
            let re =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,]+$)[\da-zA-Z-=+_.,]{6,18}$/
            if (value) {
              if (value.length < 6 || value.length > 18) {
                return callback(new Error("请输入6位至18位密码"))
              }
              if (!re.test(value)) {
                return callback(new Error("密码中需要包含字母、数字、符号其中两种形式"))
              }
            }
            callback()
          },
          trigger: "blur"
        }],
        password: [{
          validator: (rule, value, callback) => {
            let re =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,]+$)[\da-zA-Z-=+_.,]{6,18}$/
            if (value && !this.form.oldPassword ) {
              return callback(new Error("要修改密码，请先输入当前密码"))
            }
            if (value.length < 6 || value.length > 18) {
              return callback(new Error("请输入6位至18位密码"))
            }
            if (!re.test(value)) {
              return callback(new Error("密码中需要包含字母、数字、符号其中两种形式"))
            }
            callback()
          },
          trigger: "blur"
        }]
      },
      msgVisible: false,
      msgLoading: false,
      msgParams: {
        pageNum: 1,
        pageSize: 4,
        readStatus: ''
      },
      msgData: {
        total: 60,
        list: []
      },
      typeText: {
        'BOOK_CREATE_SERIES': '1v1系列预约成功',
        'BOOK_CREATE_PRODUCT': '1v1单品预约成功',
        'BOOK_CANCEL_SERIES': '1v1系列预约取消',
        'BOOK_CANCEL_PRODUCT': '1v1单品预约取消',
        'LIVE_START_SERIES': '本场系列直播已开始，请留意',
        'LIVE_START_PRODUCT': '本场单品直播已开始，请留意'
      },
      unreadNum: 0
    };
  },

  computed: {
    breads() {
      return this.$route.matched
      .map((item) => ({ title: item.meta.title, name: item.name, redirect: item.redirect  }))
      .filter((item) => item.title)
    },
    user() {
      return this.$store.state.user.user;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  mounted() {
    this.getNoReadNum()
    // 腾讯IM 推送消息
    this.$bus.$on('getLivePush', () => {
      this.getNoReadNum()
    })
  },
  methods: {
    async logout() {
      await this.$store.dispatch("FedLogOut");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },

    initForm() {
      Object.assign(this.form, {
        userId: this.user.userId,
        nickName: this.user.nickName,
        avatar: this.user.avatar,
      })
    },

    uploadAvatar(info) {
      this.form.avatar = info.url
    },

    async updateUserInfo() {
      if (Object.entries(this.form).some(([_, value]) => value)) {
        if (this.form.password || this.form.oldPassword) {
          if (this.form.password && this.form.oldPassword) {
            await editUserProfile(this.form)
            this.$message.success("保存成功");
            this.$store.dispatch("GetUserInfo");
            setTimeout(() => {
              this.$forceUpdate();
            }, 2000);
            return
          }
        }
        await editUserProfile({
          userId: this.form.userId,
          nickName: this.form.nickName,
          avatar: this.form.avatar
        })

        this.$message.success("保存成功");
        this.$store.dispatch("GetUserInfo");
        setTimeout(() => {
          this.$forceUpdate();
        }, 2000);
      }

      this.userVisible = false;
    },

    async getMsgList() {
      this.msgLoading = true
      try {
        const { rows, total } = await getLiveNotice(this.msgParams)
        rows.map(v => {
          v.liveJson = JSON.parse(v.liveJson)
          v.dateTime = this.$moment(v.liveJson.startTime).format('MM-DD hh:mm')
          v.dateText = this.$moment(v.liveJson.startTime).format('YYYY年MM月DD日')
          v.startTimeArr = this.$moment(v.liveJson.startTime).format('hh-mm').split('-')
          v.endTimeArr = this.$moment(v.liveJson.endTime).format('hh-mm').split('-')
          v.isToady = this.$moment(v.liveJson.startTime).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')
        })
        this.msgData = {
          total: Number(total),
          list: rows
        }
        setTimeout(() => {
          this.msgLoading = false
        }, 500)
      } catch {
        this.msgLoading = false
      }
    },
    changeMsgList(readStatus = '') {
      Object.assign(this.msgParams, {
        pageNum: 1,
        pageSize: 4,
        readStatus
      })
      this.getMsgList()
    },
    onCurrentChange(page) {
      Object.assign(this.msgParams, {
        pageNum: page
      })
      this.getMsgList()
    },
    async getNoReadNum() {
      const { data } = await getNoRead()
      this.unreadNum = Number(data)
    },
    async handleReadMsg(item, index) {
      this.msgVisible = false
      if (item.readStatus == 0) {
        await hasRead({
          id: item.id
        })
        this.msgData.list[index].readStatus = 1
        this.getNoReadNum()
      }
      this.$router.push({ name: "LiveControl", query: { id: item.liveId } })
    },
    async handleReadAllMsg() {
      await hasReadAll()
      this.getMsgList()
      this.getNoReadNum()
    }
  }
};
</script>

<style lang="scss" scoped>
.head-bar {
  padding: 0 24px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #F6F6F6;
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    font-weight: 700;
  }

  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .el-icon-arrow-down {
    color: #000;
    font-size: 10px;
    font-weight: bold;
    margin-left: 8px;
  }
}

.user-block {
  margin: -12px;
  width: 240px;
  z-index: 100;
  position: relative;

  .user-info {
    padding: 18px;
    background: #f2f7ff;
  }

  .operation {
    width: 100%;

    .el-button {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.avatar-cover {
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

  &.is-upload:after {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    background-image: url("~@/assets/upload.png");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.info-wrap {
	height: 20px;
	width: 20px;
	line-height: 20px;
	background: url('~@/assets/notify.png');
	background-size: contain;
	margin-right: 16px;
	cursor: pointer;
	position: relative;
  & > .info-content {
    padding: 0 4px;
    min-width: 16px;
    width: unset;
    height: 16px;
    line-height: 16px;
    background: #FF4D4F;
    border-radius: 11px;
    position: absolute;
    right: -10%;
    top: -4px;
    font-weight: 600;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    zoom: .75;
  }
  .than-ten {
    right: -35%;
  }
  .less-hundred {
    right: -70%;
  }
}
.live-msg {
  width: 432px;
  height: 230px;
  background: #fff;
  border: none;
  padding: 16px 16px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid #F5F5F7;
  margin-bottom: 16px;
  cursor: pointer;
  &.read {
    opacity: 0.5;
  }
  &__header {
    height: 40px;
    span {
      color: rgba(0, 0, 0, .5);
      font-size: 18px;
      line-height: 40px;
    }
  }
  .live-info {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 12px;
    }
    &__name {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      width: 60px;
    }
    &__line {
      width: 1.5px;
      height: 20px;
      background: rgba(0, 0, 0, 0.15);
      margin: 0 8px;
    }
    &__desc {
      color: #000;
      font-size: 20px;
      font-weight: 400;
    }
  }
  &__date {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .live-date {
    margin: 24px 0 12px;
    &__item {
      position: relative;
      text-align: center;
      width: 52px;
      height: 78px;
      line-height: 78px;
      border-radius: 6px;
      background: url('~@/assets/live_date.png') no-repeat center center;
      background-size: 100%;
      color: #000;
      font-weight: 700;
      font-size: 36px;
    }
    &__symbol {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 28px;
    }
    &__point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #000;
      margin: 4px 0;
    }
    &__line {
      width: 14px;
      height: 2.5px;
      background: #000;
    }
  }
}
</style>
<style lang="scss">
.msg-pop {
  width: 472px;
  height: 620px;
  border-radius: 16px!important;
  box-shadow: 0px 16px 48px 0px rgba(17, 17, 17, 0.12)!important;
  border: none!important;
  box-sizing: border-box;
  padding: 0!important;
  .no-data {
    display: flex;
    align-items: center;
    height: 100%;
    color: #737A8C;
    font-size: 18px;
    font-weight: 400;
  }
  &__hd {
    height: 64px;
    padding: 0 20px;
    border-bottom: 1px solid #F2F2F4;
  }
  .msg-tab {
    &__item {
      font-size: 18px;
      font-weight: 400;
      color: #737A8C;
      margin-right: 16px;
      cursor: pointer;
      &--sel {
        color: #000;
        font-weight: 500;
      }
      &--read {
        color: #2C66FF;
      }
    }
  }
  .el-icon-close {
    color: #6E777F;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
  }
  &__bd {
    flex-direction: column;
    height: 492px;
    padding: 16px 0;
    margin: 0 10px;
    box-sizing: border-box;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
      opacity: .2;
      background: fade(#eff0f2,60%);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
      border-radius: 0;
      background: fade(#eff0f2,30%);
    }
  }
  &__ft {
    height: 64px;
    border-top: 1px solid #F2F2F4;
    .el-pager {
      li {
        line-height: 31px;
        height: 31px;
        min-width: 31px;
        margin: 0 2px;
        background-color: #fff;
        font-size: 13px;
        font-weight: 400;
        color: #6E7491;
        &.active {
          background-color: #3a70ff;
          border-radius: 7px;
          color: #fff;
        }
      }
    }
  }
}
</style>

// 洽太埋点封装
import store from '@/store'

export function trackFun(fn, obj, merchantIdent) {
  const userType = store.getters.user.merchantIdent.toString()
  if (userType === merchantIdent) {
    this.ctail.track(fn, obj)
  }
}
// 调用方式
/*
EnterLive，事件名
obj，传参比如{roomId: roomID}
merchantIdent, 项目名(只让埋点功能在某一个项目中展示),比如 'louisv'
trackFun('EnterLive', {
  roomId: roomID
}, 'louisv')
 */

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-notification-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        class: ["el-notification", "live-notification", _vm.horizontalClass],
        style: _vm.positionStyle,
        attrs: { role: "alert" },
        on: {
          mouseenter: function ($event) {
            return _vm.clearTimer()
          },
          mouseleave: function ($event) {
            return _vm.startTimer()
          },
          click: _vm.handleClick,
        },
      },
      [
        _c("div", { staticClass: "live-notification__header flex between" }, [
          _c(
            "div",
            { staticClass: "live-info flex middle" },
            [
              !["LIVE_START_SERIES", "LIVE_START_PRODUCT"].includes(
                _vm.message.pushType
              )
                ? [
                    _c("img", {
                      attrs: { src: _vm.message.fromAvatar, alt: "" },
                    }),
                    _c("div", { staticClass: "live-info__name ellipsis" }, [
                      _vm._v(_vm._s(_vm.message.fromNickName)),
                    ]),
                    _c("div", { staticClass: "live-info__line" }),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "live-info__desc" }, [
                _vm._v(_vm._s(_vm.typeText[_vm.message.pushType])),
              ]),
            ],
            2
          ),
          _c("span", [_vm._v(_vm._s(_vm.message.dateTime))]),
        ]),
        _c("div", { staticClass: "live-notification__content" }, [
          _c("div", { staticClass: "live-date flex middle center" }, [
            _c("div", { staticClass: "live-date__item" }, [
              _vm._v(" " + _vm._s(_vm.message.startTimeArr[0]) + " "),
            ]),
            _c("div", { staticClass: "live-date__symbol" }, [
              _c("div", { staticClass: "live-date__point" }),
              _c("div", { staticClass: "live-date__point" }),
            ]),
            _c("div", { staticClass: "live-date__item" }, [
              _vm._v(" " + _vm._s(_vm.message.startTimeArr[1]) + " "),
            ]),
            _c("div", { staticClass: "live-date__symbol" }, [
              _c("div", { staticClass: "live-date__line" }),
            ]),
            _c("div", { staticClass: "live-date__item" }, [
              _vm._v(" " + _vm._s(_vm.message.endTimeArr[0]) + " "),
            ]),
            _c("div", { staticClass: "live-date__symbol" }, [
              _c("div", { staticClass: "live-date__point" }),
              _c("div", { staticClass: "live-date__point" }),
            ]),
            _c("div", { staticClass: "live-date__item" }, [
              _vm._v(" " + _vm._s(_vm.message.endTimeArr[1]) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "live-notification__date flex middle center" },
            [
              _vm._v(" " + _vm._s(_vm.message.dateText) + " "),
              _vm.message.isToady ? [_vm._v(" （今天） ")] : _vm._e(),
            ],
            2
          ),
        ]),
        ["LIVE_START_SERIES", "LIVE_START_PRODUCT"].includes(
          _vm.message.pushType
        )
          ? _c("div", { staticClass: "live-action flex center middle" }, [
              _c(
                "div",
                {
                  staticClass: "live-action__btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCancel($event)
                    },
                  },
                },
                [_vm._v(" 取消直播 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "live-action__btn live-action__btn--sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClick($event)
                    },
                  },
                },
                [_vm._v(" 立即开播 ")]
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "live-notification__close",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close($event)
              },
            },
          },
          [_c("svg-icon", { attrs: { "icon-class": "notification-close" } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
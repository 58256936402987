<template>
  <transition name="el-notification-fade">
    <div
      :class="['el-notification', 'live-notification', horizontalClass]"
      v-show="visible"
      :style="positionStyle"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      @click="handleClick"
      role="alert"
    >
      <div class="live-notification__header flex between">
        <div class="live-info flex middle">
          <template v-if="!['LIVE_START_SERIES', 'LIVE_START_PRODUCT'].includes(message.pushType)">
            <img :src="message.fromAvatar" alt="" />
            <div class="live-info__name ellipsis">{{ message.fromNickName }}</div>
            <div class="live-info__line"></div>
          </template>
          <div class="live-info__desc">{{ typeText[message.pushType] }}</div>
        </div>
        <span>{{ message.dateTime }}</span>
      </div>
      <div class="live-notification__content">
        <div class="live-date flex middle center">
          <div class="live-date__item">
            {{ message.startTimeArr[0] }}
          </div>
          <div class="live-date__symbol">
            <div class="live-date__point"></div>
            <div class="live-date__point"></div>
          </div>
          <div class="live-date__item">
            {{ message.startTimeArr[1] }}
          </div>
          <div class="live-date__symbol">
            <div class="live-date__line"></div>
          </div>
          <div class="live-date__item">
            {{ message.endTimeArr[0] }}
          </div>
          <div class="live-date__symbol">
            <div class="live-date__point"></div>
            <div class="live-date__point"></div>
          </div>
          <div class="live-date__item">
            {{ message.endTimeArr[1] }}
          </div>
        </div>
        <div class="live-notification__date flex middle center">
          {{ message.dateText }}
          <template v-if="message.isToady">
            （今天）
          </template>
        </div>
      </div>
      <div v-if="['LIVE_START_SERIES', 'LIVE_START_PRODUCT'].includes(message.pushType)" class="live-action flex center middle">
        <div class="live-action__btn" @click.stop="handleCancel">
          取消直播
        </div>
        <div class="live-action__btn live-action__btn--sel" @click.stop="handleClick">
          立即开播
        </div>
      </div>
      <div class="live-notification__close" @click.stop="close">
        <svg-icon icon-class="notification-close" />
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import router from '../../../../router'
  import livLiveConsole from "@/api/live/livLiveConsole";
  export default {
    data() {
      return {
        visible: false,
        duration: 4500,
        onClose: null,
        onClick: null,
        closed: false,
        verticalOffset: 0,
        timer: null,
        position: 'top-right',
        message: {
          startTimeArr: [],
          endTimeArr: []
        },
        typeText: {
          'BOOK_CREATE_SERIES': '1v1系列预约成功',
          'BOOK_CREATE_PRODUCT': '1v1单品预约成功',
          'BOOK_CANCEL_SERIES': '1v1系列预约取消',
          'BOOK_CANCEL_PRODUCT': '1v1单品预约取消',
          'LIVE_START_SERIES': '本场系列直播已开始，请留意',
          'LIVE_START_PRODUCT': '本场单品直播已开始，请留意'
        }
      };
    },

    computed: {

      horizontalClass() {
        return this.position.indexOf('right') > -1 ? 'right' : 'left';
      },

      verticalProperty() {
        return /^top-/.test(this.position) ? 'top' : 'bottom';
      },

      positionStyle() {
        return {
          [this.verticalProperty]: `${ this.verticalOffset }px`
        };
      }
    },

    watch: {
      closed(newVal) {
        if (newVal) {
          this.visible = false;
          this.$el.addEventListener('transitionend', this.destroyElement);
        }
      }
    },

    methods: {
      destroyElement() {
        this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },

      handleClick() {
        router.push({ name: "LiveControl", query: { id: this.message.id } })
        this.close()
      },

      close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
          this.onClose();
        }
      },

      clearTimer() {
        clearTimeout(this.timer);
      },

      startTimer() {
        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            if (!this.closed) {
              this.close();
            }
          }, this.duration);
        }
      },
      keydown(e) {
        if (e.keyCode === 46 || e.keyCode === 8) {
          this.clearTimer(); // detele 取消倒计时
        } else if (e.keyCode === 27) { // esc关闭消息
          if (!this.closed) {
            this.close();
          }
        } else {
          this.startTimer(); // 恢复倒计时
        }
      },
      handleCancel() {
        this.$confirm("是否确定要取消直播", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: 'custom-confirm',
        }).then(() => {
          livLiveConsole.cancelLive({
            id: this.message.id,
            cancelRemark: "取消直播",
          }).then(() => {
            this.$message.success("取消成功！");
          });
        });
      }
    },
    mounted() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
      let liveInfo = JSON.parse(this.message.live)
      liveInfo.dateTime = this.$moment(liveInfo.startTime).format('MM-DD hh:mm')
      liveInfo.dateText = this.$moment(liveInfo.startTime).format('YYYY年MM月DD日')
      liveInfo.startTimeArr = this.$moment(liveInfo.startTime).format('hh-mm').split('-')
      liveInfo.endTimeArr = this.$moment(liveInfo.endTime).format('hh-mm').split('-')
      liveInfo.isToady = this.$moment(liveInfo.startTime).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')
      Object.assign(this.message, {
        ...liveInfo
      })
      document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown);
    }
  };
</script>

<style lang="scss" scoped>
  .live-notification {
    width: 432px;
    min-height: 230px;
    background: #fff;
    box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
    border: none;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
    overflow: initial;
    &:hover {
      cursor: pointer;
      .live-notification__close {
        display: block;
      }
    }
    &__header {
      height: 40px;
      span {
        color: rgba(0, 0, 0, .5);
        font-size: 18px;
        line-height: 40px;
      }
    }
    .live-info {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
      }
      &__name {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        width: 60px;
      }
      &__line {
        width: 1.5px;
        height: 20px;
        background: rgba(0, 0, 0, 0.15);
        margin: 0 8px;
      }
      &__desc {
        color: #000;
        font-size: 20px;
        font-weight: 400;
      }
    }
    &__date {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    &__close {
      display: none;
      position: absolute;
      top: -10px;
      left: -16px;
      .svg-icon {
        width: 32px;
        height: 32px;
      }
    }
    .live-date {
      margin: 24px 0 12px;
      &__item {
        position: relative;
        text-align: center;
        width: 52px;
        height: 78px;
        line-height: 78px;
        border-radius: 6px;
        background: url('~@/assets/live_date.png') no-repeat center center;
        background-size: 100%;
        color: #000;
        font-weight: 700;
        font-size: 36px;
      }
      &__symbol {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 28px;
      }
      &__point {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #000;
        margin: 4px 0;
      }
      &__line {
        width: 14px;
        height: 2.5px;
        background: #000;
      }
    }
    .live-action {
      margin: 26px 0 14px;
      &__btn {
        text-align: center;
        width: 194px;
        height: 44px;
        line-height: 44px;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        border-radius: 6px;
        border: 1px solid #F1F2F3;
        margin: 0 6px;
        cursor: pointer;
        &--sel {
          border-color: #2C66FF;
          background: #2C66FF;
          color: #fff;
        }
      }
    }
  }
</style>
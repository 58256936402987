import request from '@/utils/request'

// 修改用户信息
export function editUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data
  })
}

// 获取未读消息数
export function getNoRead(params) {
  return request({
    url: '/liveNotice/getNoRead',
    method: 'get',
    params
  })
}

// 查询直播间通知
export function getLiveNotice(params) {
  return request({
    url: '/liveNotice/getPage',
    method: 'get',
    params
  })
}

// 标识全部已读
export function hasReadAll(data) {
  return request({
    url: '/liveNotice/hasReadAll',
    method: 'post',
    data
  })
}

// 标识已读
export function hasRead(data) {
  return request({
    url: '/liveNotice/hasRead',
    method: 'post',
    data
  })
}

<template>
  <div>
    <draggable
      v-model="imgList"
      class="dragbox"
      @start="drag = true"
      @end="drag = false"
    >
      <slot>
        <div v-for="(item, index) in imgList" :key="index" class="img-item">
          <el-image v-if="posterType === 'image'" :src="item.url" fit="cover" />

          <video v-else class="el-image" :src="item.url" />

          <svg-icon
            icon-class="img-remove"
            class="img-remove"
            @click="handleRemove(index)"
          />

          <div v-if="type === 'product' && index === 0" class="tag">主图</div>
        </div>
      </slot>

      <mf-uploader
        v-show="imgList.length < fileLimit && !loading"
        ref="uploader"
        :accept="accept"
        v-bind="$attrs"
        :type="type"
        class="upload"
        :data="uploadData"
        :before-upload="handleBeforeUpload"
        @success="handleSuccess"
        @error="handleError"
        @getImgSizeFun="getImgSizeFun1"
      >
        <i class="el-icon-plus" />
      </mf-uploader>

      <div
        v-show="loading"
        v-loading="loading"
        class="upload loading"
        element-loading-background="transparent"
      />
    </draggable>

    <p v-if="tip" class="tip">{{ tip }}</p>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MfUploader from '../functional/Uploader'

export default {
  name: 'MbImageUploader',

  components: {
    draggable,
    MfUploader
  },

  props: {
    value: {
      required: true,
      type: Array | String
    },

    accept: {
      type: String,
      default: 'image/jpeg, image/png'
    },

    fileLimit: {
      type: Number,
      default: 9
    },

    tip: String,

    uploadData: {
      type: Object,
      default: null
    },

    posterType: {
      type: String,
      default: 'image'
    },

    type: {
      type: String,
      default: 'common'
    },

    beforeUpload: Function
  },

  data() {
    return {
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },

      drag: false,
      theUploadNumber: 0,
      loading: false
    }
  },

  computed: {
    multiple() {
      return this.imgList.length < this.fileLimit - 1
    },

    imgList: {
      get() {
        if (this.fileLimit === 1) {
          return this.value ? [this.value] : []
        } else {
          return this.value || []
        }
      },

      set(val) {
        this.updateList(val)
      }
    }
  },

  methods: {
    updateList(list) {
      const val = list || this.imgList
      if (this.fileLimit === 1) {
        this.$emit('input', val[0] || '')
      } else {
        this.$emit('input', val || [])
      }
    },

    handleBeforeUpload(file) {
      if (this.theUploadNumber + this.imgList.length > this.fileLimit) {
        return this.$message.error('上传图片数量超出限制!')
      }

      if (this.beforeUpload && !this.beforeUpload(file)) {
        return false
      }

      this.theUploadNumber += 1
      this.loading = true

      return true
    },

    handleSuccess(info) {
      this.loading = false

      this.theUploadNumber -= 1
      this.imgList.push({
        url: info.url,
        path: info.fileName
      })
      this.updateList()
    },
    getImgSizeFun1(url) {
      this.$emit("getImgSizeFun2", url);
    },
    handleError() {
      this.loading = false
      this.theUploadNumber -= 1
    },

    handleRemove(index) {
      this.imgList.splice(index, 1)
      this.updateList()
    },

    clearFiles() {
      this.$refs['uploader'].clearFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
.img-item,
.upload {
  position: relative;
  margin: 0 8px 8px 0;
  width: 100px;
  height: 100px;
  border-radius: 12px;
  display: inline-block;
  vertical-align: middle;
  .tag {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 40px;
    height: 18px;
    line-height: 18px;
    background: rgba(0, 0, 0, .4);
    border-radius: 12px 0 6px 0;
    font-weight: 400;
    color: #fff;
    font-size: 10px;
  }
}

.upload {
  border: 1px dashed #dcdee0;
  background: #f8f9fa;
  font-size: 28px;
  color: #646566;
  line-height: 100px;
  text-align: center;

  ::v-deep .el-upload {
    width: 100%;
  }

  &.loading {
    line-height: 1;
    overflow: hidden;

    ::v-deep .el-loading-spinner {
      margin-top: -14px;

      .circular {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.img-item {
  position: relative;

  .el-image {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  &:hover .img-remove {
    display: block;
  }

  .img-remove {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 16px;
    color: #eaecef;
    cursor: pointer;
    display: none;
  }
}

.tip {
  color: #a7aaaa;
  line-height: 1.5;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "draggable",
        {
          staticClass: "dragbox",
          on: {
            start: function ($event) {
              _vm.drag = true
            },
            end: function ($event) {
              _vm.drag = false
            },
          },
          model: {
            value: _vm.imgList,
            callback: function ($$v) {
              _vm.imgList = $$v
            },
            expression: "imgList",
          },
        },
        [
          _vm._t(
            "default",
            _vm._l(_vm.imgList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "img-item" },
                [
                  _vm.posterType === "image"
                    ? _c("el-image", { attrs: { src: item.url, fit: "cover" } })
                    : _c("video", {
                        staticClass: "el-image",
                        attrs: { src: item.url },
                      }),
                  _c("svg-icon", {
                    staticClass: "img-remove",
                    attrs: { "icon-class": "img-remove" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemove(index)
                      },
                    },
                  }),
                  _vm.type === "product" && index === 0
                    ? _c("div", { staticClass: "tag" }, [_vm._v("主图")])
                    : _vm._e(),
                ],
                1
              )
            })
          ),
          _c(
            "mf-uploader",
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imgList.length < _vm.fileLimit && !_vm.loading,
                    expression: "imgList.length < fileLimit && !loading",
                  },
                ],
                ref: "uploader",
                staticClass: "upload",
                attrs: {
                  accept: _vm.accept,
                  type: _vm.type,
                  data: _vm.uploadData,
                  "before-upload": _vm.handleBeforeUpload,
                },
                on: {
                  success: _vm.handleSuccess,
                  error: _vm.handleError,
                  getImgSizeFun: _vm.getImgSizeFun1,
                },
              },
              "mf-uploader",
              _vm.$attrs,
              false
            ),
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "upload loading",
            attrs: { "element-loading-background": "transparent" },
          }),
        ],
        2
      ),
      _vm.tip
        ? _c("p", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <transition name="el-notification-fade">
    <div
      :class="['el-notification', 'room-notification', horizontalClass]"
      v-show="visible"
      :style="positionStyle"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      role="alert"
    >
      <div class="room-notification__close" @click="close">
        <svg-icon icon-class="notification-close" />
      </div>
      <div class="r-goods flex">
        <div v-if="message.sliderImage.length" class="r-goods__pic">
          <el-image
            style="display: block; width: 148px; height: 148px; border-radius: 10px;"
            :src="message.sliderImage[0].showPath"
            fit="cover"
          />
        </div>
        <div class="r-goods__info">
          <div class="r-goods__title">{{ typeText[message.pushType] }}</div>
          <div class="r-goods__name" :class="message.pushType === 'MY_LIKE' ? 'ellipsis' : 'ellipsis-2'">{{ message.storeName }}</div>
          <div v-if="message.pushType === 'MY_LIKE'" class="r-goods__btn" @click="handlePush">推送</div>
          <div v-else class="r-goods__price">¥ {{ message.price }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import router from '../../../../router'
  import livLiveConsole from "@/api/live/livLiveConsole";
  export default {
    data() {
      return {
        visible: false,
        duration: 4500,
        onClose: null,
        onClick: null,
        closed: false,
        verticalOffset: 0,
        timer: null,
        position: 'top-right',
        message: {
          sliderImage: []
        },
        typeText: {
          'CREATE_ORDER': '购买 1 件',
          'MY_LIKE': '想要咨询',
          'ADD_CART': '加购 1 件'
        }
      };
    },

    computed: {

      horizontalClass() {
        return this.position.indexOf('right') > -1 ? 'right' : 'left';
      },

      verticalProperty() {
        return /^top-/.test(this.position) ? 'top' : 'bottom';
      },

      positionStyle() {
        return {
          [this.verticalProperty]: `${ this.verticalOffset }px`
        };
      }
    },

    watch: {
      closed(newVal) {
        if (newVal) {
          this.visible = false;
          this.$el.addEventListener('transitionend', this.destroyElement);
        }
      }
    },

    methods: {
      destroyElement() {
        this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },

      close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
          this.onClose();
        }
      },

      clearTimer() {
        clearTimeout(this.timer);
      },

      startTimer() {
        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            if (!this.closed) {
              this.close();
            }
          }, this.duration);
        }
      },
      keydown(e) {
        if (e.keyCode === 46 || e.keyCode === 8) {
          this.clearTimer(); // detele 取消倒计时
        } else if (e.keyCode === 27) { // esc关闭消息
          if (!this.closed) {
            this.close();
          }
        } else {
          this.startTimer(); // 恢复倒计时
        }
      },
      handlePush() {
        console.log(router)
        livLiveConsole.sendProduct({
          liveId: router.history.current.query.id,
          productId: this.message.id,
        }).then(() => {
          this.$message.success("推送成功！");
          this.close()
        });
      }
    },
    mounted() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
      Object.assign(this.message, {
        ...this.message.product
      })
      document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown);
    }
  };
</script>

<style lang="scss" scoped>
  .room-notification {
    width: 432px;
    height: 180px;
    background: #fff;
    box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
    border: none;
    padding: 16px;
    box-sizing: border-box;
    overflow: initial;
    &:hover {
      .room-notification__close {
        display: block;
      }
    }
    &__close {
      display: none;
      position: absolute;
      top: -10px;
      left: -16px;
      .svg-icon {
        width: 32px;
        height: 32px;
      }
    }
    .r-goods {
      text-align: left;
      &__info {
        flex: 1;
        max-width: 236px;
        position: relative;
        margin-left: 16px;
      }
      &__title {
        color: rgba(0, 0, 0, .5);
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 6px;
      }
      &__name {
        color: #000;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
      }
      &__price {
        position: absolute;
        left: 0;
        bottom: 0;
        color: rgba(0, 0, 0, .3);
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
      }
      &__btn {
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        margin-top: 16px;
        width: 236px;
        height: 56px;
        line-height: 56px;
        border: 1px solid #9DB8FD;
        border-radius: 12px;
        color: #2C66FF;
        font-size: 24px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
</style>
import axios from 'axios'
import { getToken } from '@/utils/auth'

function getReferrer(e, t) {
  return document.referrer
}

function getURL(e) {
  return isString(e) ? decodeURI(e = trim(e)) : decodeURI(location.href)
}

function isString(e) {
  return "[object String]" == Object.prototype.toString.call(e)
}

function trim(e) {
  return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "")
}

function getHostname(e, t) {
  return ""
}

function base64Encode(e) {
  var t, a, r, i, s, n = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    o = 0,
    c = 0,
    u = "",
    p = [];
  if (!e) return e;
  e = utf8Encode(e);
  do {
    t = (s = e.charCodeAt(o++) << 16 | e.charCodeAt(o++) << 8 | e.charCodeAt(o++)) >> 18 & 63, a = s >> 12 & 63, r = s >> 6 & 63, i = 63 & s, p[c++] = n.charAt(t) + n.charAt(a) + n.charAt(r) + n.charAt(i)
  } while (o < e.length);
  switch (u = p.join(""), e.length % 3) {
    case 1:
      u = u.slice(0, -2) + "==";
      break;
    case 2:
      u = u.slice(0, -1) + "="
  }
  return u
}
function utf8Encode(e) {
  var t, a, r, i, s = "";
  for (t = a = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, i = 0; i < r; i++) {
    var n = e.charCodeAt(i),
      o = null;
    n < 128 ? a++ : o = n > 127 && n < 2048 ? String.fromCharCode(n >> 6 | 192, 63 & n | 128) : String.fromCharCode(n >> 12 | 224, n >> 6 & 63 | 128, 63 & n | 128), null !== o && (a > t && (s += e.substring(t, a)), s += o, t = a = i + 1)
  }
  return a > t && (s += e.substring(t, e.length)), s
}

const ctail = {
  util: {

  },
  pageProp: {},
  setting: {
    init: false,
  },
  properties: function () {
    var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0,
      width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0;
    return {
      $timezone_offset: (new Date).getTimezoneOffset(),
      $screen_height: Number(screen.height) || 0,
      $screen_width: Number(screen.width) || 0,
      $viewport_height: height,
      $viewport_width: width,
      $lib: "js",
      $lib_version: "1.0.0",
      url: location.href,
      $referrer: document.referrer,
      $latest_referrer: "",
      $event_duration: 0,
      time: new Date().getTime(),
      _flush_time: new Date().getTime()
    }
  },
  /**
   * 初始化
   * @param option
   */
  init(option){
    if(this.setting.init) return false
    this.setting.init = true
    this.initPage()
    this.sendStrategy.init()
  },
  initPage: function () {
    var e = getReferrer(), t = getURL();
    this.pageProp = {
        referrer: e,
        referrer_host: e ? getHostname(e) : "",
        url: t,
        url_host: getHostname(t, "url_host取值异常"),
        url_domain: "",
    }
  },
  buildData: function (event,d) {
    var t = Object.assign({
      event: event
    },d,this.pageProp,this.properties())
    return {data: base64Encode(JSON.stringify(t))}
  },
  /**
   * 事件触发
   * @param event
   * @param data
   */
  track(event,data) {
    this.sendStrategy.send(event, Object.assign({type: 'track'},data))
  },
  /**
   * 数据存储
   */
  store: {
    data: [],
    add(d){
      this.data.push(d)
    },
    remove(d){

    },
    clear(){

    }
  },
  /**
   * 发送代理
   */
  sendStrategy: {
    dataHasSend: true,
    dataHasChange: false,
    syncStorage: true,
    failTime: 0,
    init: function() {
      //初始化启动读和写线程
      //this.batchInterval()
    },
    send: function(event,data) {
      var that = this
      that.dataHasChange = true
      //this.store.add(event)
      setTimeout(function() {
        that.request(event,data)
      }, 10)
    },
    request: function(event,d) {
      var data = ctail.buildData(event,d)
      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_API+'/api/live/brand/ctkWebData/create?track=web',
        headers: {"Authorization": getToken()},
        data: data,
      }).then(res =>{
        console.log("res:"+JSON.stringify(res))
      }).catch(function (error) {
          console.log("error:"+error);
      })
    },
    batchSend: function() {
      if (this.dataHasSend) {

      }
    },
    batchRemove: function(e) {

    },
    batchWrite: function() {

    },
    batchInterval: function() {
      var that = this;

      //批量写
      function t1() {
        setTimeout(function() {
          that.batchWrite(), t1()
        }, 500)
      }
      t1()

      //批量读
      function t2() {
        setTimeout(function() {
          that.batchSend(), t2()
        }, 2000)
      }
      t2()
    }
  }
}
ctail.init({

})
export default ctail

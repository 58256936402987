<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}" @click="toggle">
    <transition name="sidebarLogoFade">
      <div v-if="collapse" class="sidebar-logo-link">
        <svg-icon class="svg-menu" icon-class="menu-left-arrow" />
      </div>
      <div v-else class="sidebar-logo-link">
        <!-- <svg-icon class="sidebar-logo" icon-class="lp-admin_logo" />
          <span class="logo-title">LIVE</span> -->
        <div class="sidebar-box">
          <img class="logo" src="@/assets/login_images/login_chatail.png" alt="">
          <svg-icon class="svg-menu" icon-class="menu-right-arrow" />
        </div>
      </div>
    </transition>
    <svg-icon class="svg-menu" icon-class="menu-left-arrow" />
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: 'Call Center',
      logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46--e9e686ef5251.png'
    }
  },
  methods:{
    toggle(){
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 81px;
  line-height: 81px;
  background: #ffffff;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    & .logo-title {
      display: block;
      font-size: 32px;
      letter-spacing: 2px;
    }
    & .sidebar-logo {
      width: 138px;
      height: 22px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
.sidebar-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  box-sizing: border-box;
}
.svg-menu {
  width: 14px !important;
  height: 13px !important;
  display: inline-block;
  margin: 0 !important;
}
.logo {
  width: 73px;
  height: 20px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-notification-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        class: ["el-notification", "room-notification", _vm.horizontalClass],
        style: _vm.positionStyle,
        attrs: { role: "alert" },
        on: {
          mouseenter: function ($event) {
            return _vm.clearTimer()
          },
          mouseleave: function ($event) {
            return _vm.startTimer()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "room-notification__close", on: { click: _vm.close } },
          [_c("svg-icon", { attrs: { "icon-class": "notification-close" } })],
          1
        ),
        _c("div", { staticClass: "r-goods flex" }, [
          _vm.message.sliderImage.length
            ? _c(
                "div",
                { staticClass: "r-goods__pic" },
                [
                  _c("el-image", {
                    staticStyle: {
                      display: "block",
                      width: "148px",
                      height: "148px",
                      "border-radius": "10px",
                    },
                    attrs: {
                      src: _vm.message.sliderImage[0].showPath,
                      fit: "cover",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "r-goods__info" }, [
            _c("div", { staticClass: "r-goods__title" }, [
              _vm._v(_vm._s(_vm.typeText[_vm.message.pushType])),
            ]),
            _c(
              "div",
              {
                staticClass: "r-goods__name",
                class:
                  _vm.message.pushType === "MY_LIKE"
                    ? "ellipsis"
                    : "ellipsis-2",
              },
              [_vm._v(_vm._s(_vm.message.storeName))]
            ),
            _vm.message.pushType === "MY_LIKE"
              ? _c(
                  "div",
                  {
                    staticClass: "r-goods__btn",
                    on: { click: _vm.handlePush },
                  },
                  [_vm._v("推送")]
                )
              : _c("div", { staticClass: "r-goods__price" }, [
                  _vm._v("¥ " + _vm._s(_vm.message.price)),
                ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-upload
    ref="uploader"
    :multiple="multiple"
    :accept="accept"
    :action="action"
    :headers="headers"
    :data="data"
    :show-file-list="false"
    :before-upload="handleBeforeUpload"
    :on-success="handleSuccess"
    :on-error="handleError"
    :http-request="handleHttpRequest"
  >
    <slot />
  </el-upload>
</template>

<script>
import { getToken } from "@/utils/auth";
import { uploadProductTemp } from '@/api/product';
import { uploadSeriesTemp } from '@/api/series';
import common from "@/api/common";

export default {
  name: "MfUploader",

  props: {
    type: {
      type: String,
      default: "common",
    },
    accept: {
      type: String,
      default: "",
    },

    sizeLimit: {
      type: Number,
      default: 5,
    },

    fileLimit: {
      type: Number,
      default: 1,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    beforeUpload: Function,

    data: {
      type: Object,
      default: null,
    },

    imageRatio: Number,
    // 解决上传图片后无法获取图片的宽高的bug, 为true开启
    needImgSize: {
      type: Boolean,
      default: false
    },
    immediately: {
      type: Boolean,
      default: true
    },
  },

  created() {
    this.action =
      process.env.VUE_APP_BASE_API + "/api/live/brand/file/singleUpload";
    this.headers = { "Authorization": getToken() };
  },

  methods: {
    handleBeforeUpload(file) {
      var that = this;
      // 解决上传图片后无法获取图片的宽高的bug
      if (file && this.needImgSize) {
        var reader = new FileReader()
        reader.onload = function (event) {
          var base64 = event.target.result
          var img = document.createElement('img')
          img.src = base64
          img.onload = function () {
            that.$emit("getImgSizeFun", {width: img.width, height: img.height});
          }
        }
        reader.readAsDataURL(file)
      }

      if (this.accept && !this.accept.includes(file.type)) {
        this.$message.error("不接受此文件类型!");
        return false;
      }

      if (this.sizeLimit && file.size > this.sizeLimit * 1024 * 1024) {
        this.$message.error(`文件大小不能超过 ${this.sizeLimit}MB!`);
        return false;
      }

      if (this.beforeUpload) {
        return this.beforeUpload(file);
      }

      return true;
    },

    file2base64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    async handleHttpRequest(options) {
      let file = options.file;

      if (this.imageRatio && ["image/jpeg", "image/png"].includes(file.type)) {
        file =
          (await this.$imageClipper({
            ratio: this.imageRatio,
            src: window.URL.createObjectURL(
              new Blob([file], { type: file.type })
            ),
            filename: file.name,
          })) || options.file;
      }

      // 图片直接返回 base64，提交时再进行上传
      if (!this.immediately && ["image/jpeg", "image/png"].includes(file.type)) {
        const base64 = await this.file2base64(file)

        return Promise.resolve({
          body: base64,
        });
      } else {
        const data = new FormData();

        data.append("file", file);

        if (this.data) {
          Object.keys(this.data).forEach((key) => {
            data.append(key, this.data[key]);
          });
        }

        const res = this.type === 'common' ? await common.singleUpload(data) : (this.type === 'product' ? await uploadProductTemp(data) : await uploadSeriesTemp(data))
        if (res) {
          return Promise.resolve(res)
        }

        return Promise.reject();
      }
    },

    handleSuccess(res, file) {
      if (this.type === 'common') {
        this.$emit("success", {
          url: res.data
        }, file.name);
      } else {
        this.$emit("success", res.data, file.name);
      }
    },

    handleError(e) {
      console.error(e);
      this.$message.error("上传失败!");
      this.$emit("error");
    },

    handleExceed() {
      this.$message.error(`最多还能上传${this.fileLimit}个文件`);
    },

    clearFiles() {
      this.$refs["uploader"].clearFiles();
    },
  },
};
</script>

import request from '@/utils/request'

// 查询直播间
const getLiveList = (params) => {
  return request({
    url: `/live/getPage`,
    method: 'get',
    params
  })
}

// 直播间详情
const getLiveInfo = (params) => {
  return request({
    url: `/live/get`,
    method: 'get',
    params
  })
}

// 获取直播未开始数量
const liveNotStartedCount = (params) => {
  return request({
    url: '/live/liveNotStartedCount',
    method: 'get',
    params
  })
}

// 导出直播列表
const exportInviteLive = (data) => {
  return request({
    url: '/api/live/brand/livLive/exportInviteLive',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 开始直播
const startLive = (data) => {
  return request({
    url: '/liveConsole/startLive',
    method: 'post',
    data
  })
}

// 结束直播
const endLive = (data) => {
  return request({
    url: '/liveConsole/endLiveV2',
    method: 'post',
    data
  })
}

// 取消直播
const cancelLive = (data) => {
  return request({
    url: '/liveConsole/cancel',
    method: 'post',
    data
  })
}

// 获取直播间商品
const getLiveProduct = (params) => {
  return request({
    url: '/liveConsole/getLiveProduct',
    method: 'get',
    params
  })
}

// 添加商品至直播间
const addLiveProduct = (data) => {
  return request({
    url: '/liveConsole/addLiveProduct',
    method: 'post',
    data
  })
}

// 删除直播间商品
const delLiveProduct = (data) => {
  return request({
    url: '/liveConsole/delLiveProduct',
    method: 'post',
    data
  })
}

// 推送商品直播
const sendProduct = (data) => {
  return request({
    url: '/liveConsole/sendProduct',
    method: 'post',
    data
  })
}

// 置顶直播间商品-或者移动
const topLiveProduct = (data) => {
  return request({
    url: '/liveConsole/topLiveProduct',
    method: 'post',
    data
  })
}

// 生成邀约涵-返回小程序二维码
const createInvitePic = (data) => {
  return request({
    url: '/liveConsole/createInvitePic',
    method: 'post',
    data
  })
}

// 更新直播标签
const editLabelType = (data) => {
  return request({
    url: '/liveConsole/editLabelType',
    method: 'post',
    data
  })
}

// 获取直播间配置
const getConfig = (params) => {
  return request({
    url: `/liveConsole/getConfig`,
    method: 'get',
    params
  })
}

// 更新直播间配置
const updateConfig = (data) => {
  return request({
    url: `/liveConsole/updateConfig`,
    method: 'post',
    data
  })
}

// 更新备注
const updateRemark = (data) => {
  return request({
    url: `/liveConsole/updateRemark`,
    method: 'post',
    data
  })
}

// 获取直播间用户信息
const getLiveCustomerInfo = (params) => {
  return request({
    url: `/liveConsole/getLiveCustomerInfo`,
    method: 'get',
    params
  })
}

// 获取所有商品
const getAllProduct = (params) => {
  return request({
    url: `/liveConsole/getAllProduct`,
    method: 'get',
    params
  })
}

// 通知用户关闭浏览器
const noticUserCloseChrome = (data) => {
  return request({
    url: `/liveConsole/noticUserCloseChrome`,
    method: 'post',
    data
  })
}

// 保存直播间邀约海报信息
const updateInvitePost = (data) => {
  return request({
    url: `/liveConsole/updateInvitePost`,
    method: 'post',
    data
  })
}

export default {
  getLiveList,
  getLiveInfo,
  liveNotStartedCount,
  exportInviteLive,
  startLive,
  endLive,
  cancelLive,
  getLiveProduct,
  addLiveProduct,
  delLiveProduct,
  sendProduct,
  topLiveProduct,
  createInvitePic,
  editLabelType,
  getConfig,
  updateConfig,
  updateRemark,
  getLiveCustomerInfo,
  getAllProduct,
  noticUserCloseChrome,
  updateInvitePost
}

